<script>
  import { ValidationObserver } from 'vee-validate'
  import { MESSAGE_UNEXPECTED_ERROR } from '@/helpers/constants'

  /**
   * A form with built-in validation.
   */
  export default {
    components: {
      ValidationObserver,
    },
    props: {
      /** Whether to show a spinner overlay when the form is processing. */
      spinner: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        validationObserverRef: null,
        processing: false,
        processingSuccess: false,
        processingErrorMessage: null,
        processingValidationErrors: [],
      }
    },
    computed: {
      validationProviders() {
        return this.validationObserverRef?.refs ?? {}
      },
    },
    methods: {
      async validate() {
        return await this.validationObserverRef.validate()
      },
      async validateAndSubmit() {
        const validationPassed = await this.validate()
        // If validation failed, focus on the first invalid input
        if (!validationPassed) {
          const invalidValidationProviders = Object.values(this.validationProviders).filter(
            (provider) => provider?.failedRules && Object.keys(provider.failedRules).length > 0
          )
          if (invalidValidationProviders.length === 0) {
            return
          }
          const invalidInput = invalidValidationProviders[0].$parent
          if (invalidInput?.focus) {
            invalidInput.focus()
          }
          return
        }
        /** Emitted when the user submits the form and validation passes. */
        this.$emit('submit')
      },
      async process(processingPromise) {
        this.processing = true
        this.processingSuccess = false
        this.processingErrorMessage = null
        this.processingValidationErrors = []
        try {
          const result = await processingPromise
          this.processing = false
          this.processingSuccess = true
          if (this.spinner) {
            await this.$wait(1000)
          }
          return result
        } catch (error) {
          this.processing = false
          this.processingErrorMessage = error?.message || MESSAGE_UNEXPECTED_ERROR
          this.processingValidationErrors = Object.entries(error?.data?.errors ?? {})
            .map(([inputName, message]) => ({ inputName, message }))
            .filter(({ inputName }) => this.validationProviders[inputName] !== undefined)
          // Update the inputs’ validation providers to display validation errors from the back-end.
          // For some unfathomable reason, we need to $wait 0 ms here, otherwise setErrors() has no effect
          // ($nextTick() doesn’t do the trick).
          await this.$wait(0)
          let foundInvalidInput = false
          this.processingValidationErrors.forEach((validationError) => {
            const invalidValidationProvider = this.validationProviders[validationError.inputName]
            invalidValidationProvider.setErrors([validationError.message])
            // Focus on the first invalid input
            if (!foundInvalidInput) {
              const invalidInput = invalidValidationProvider.$parent
              if (invalidInput?.focus) {
                invalidInput.focus()
              }
              foundInvalidInput = true
            }
          })
          // If no input-specific validation error, scroll to the top of the form
          if (!foundInvalidInput) {
            this.validationObserverRef.$el.scrollIntoView()
          }

          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <ValidationObserver
    v-slot="{ failed: validationFailed }"
    v-ref="validationObserverRef"
    tag="div"
    :class="`${processing ? 'animate-ripple' : ''}`"
  >
    <form @submit.prevent="validateAndSubmit">
      <!-- @slot The form’s content. Provides `inputsEnabled` and `submitEnabled` props to determine whether the form’s inputs and submit button (respectively) should be enabled, and an `errorMessage` prop. -->
      <slot
        :inputs-enabled="!processing && !processingSuccess"
        :submit-enabled="!validationFailed && !processing && !processingSuccess"
        :error-message="
          processingValidationErrors.length === 0 ? processingErrorMessage : undefined
        "
      />
    </form>
    <BaseSpinner
      v-if="spinner && (processing || processingSuccess)"
      :success="processingSuccess"
      overlay="absolute"
      class="z-10"
    />
  </ValidationObserver>
</template>
